.home-container {
  padding: 0 16px; 
  margin-top: 100px;
  margin-bottom: 80px;
  top: 0;
  z-index: 1100; /* Ensures the navbar is above other content */
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
}

.card {
  display: inline-block;
  margin: 0 10px;
}