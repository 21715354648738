/* Ensure the navbar is always at the top of the screen */
.navbar {
  position: fixed; /* Make sure the navbar is fixed at the top */
  margin-bottom: 70px;
  top: 0;
  width: 100%;
  z-index: 1100; /* Ensure it's above other content */
}

/* Ensure content below the navbar doesn't overlap */
body {
  margin-top: 60px; /* Adjust this value according to the height of your navbar */
}

/* Define styles for the active nav link */
.navbar .active {
  font-weight: bold;
  color: #1976d2; /* Change to your desired active color */
}

/* Navbar.css */

/* Dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Show the dropdown content on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Style the dropdown links */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Responsive styles for mobile view */
@media (max-width: 600px) {
  .dropdown-content {
    min-width: 100%;
    left: 0;
    top: 100%;
  }
}
