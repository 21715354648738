.people-container {
  padding:0 16px; 
  margin-top: 80px;
  margin-bottom: 80px;
  top: 0;
  z-index: 1100; /* Ensures the navbar is above other content */
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Ensure it's above other content */
}
